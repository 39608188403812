/* btn
-------------------------*/

.m-btn {
    display: inline-block;
    position: relative;
    padding: 10px 25px;
    text-align: center;
    cursor: pointer;
    white-space: initial;
    @include font-size(15);
    font-weight: 600;
    line-height: 1.3;
    @include txtLine(noLine);
    transition: 0.2s all ease-in;
    &.focus,
    &:focus,
    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
    &:active,
    &.active {
        box-shadow: none;
    }
    /* default */
    &--default {
        padding: 19px 75px;
        color: #FFF;
        background: $mainColor;
        border-radius: 3px;
        &:after {
            @include position(absolute, 3px, 10px, 0, null);
            display: block;
            content: '';
            width: 13px;
            height: 13px;
            margin: auto;
            background: url('#{$images_dir}icon_arrow_right.png') no-repeat;
            background-size: 43%;
            transition: .2s all ease-in;
        }
        &:after {
            border-color: $mainColor;
            color: $mainColor;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: darken($mainColor, 10%);
            color:white;
            &:after {
                @include position(absolute, 3px, 4px, 0, null);
            }
        }
    }
    /* white */
    &--white {
        padding: 12px 40px;
        color: $mainColor;
        background: #FFF;
        border: 1px solid $mainColor;
        border-radius: 3px;
        @include mq(tab) {
            padding: 19px 75px;
        }
        &:after {
            @include position(absolute, 3px, 10px, 0, null);
            display: block;
            content: '';
            width: 13px;
            height: 13px;
            margin: auto;
            background: url('#{$images_dir}icon_arrow_right_brown.png') no-repeat;
            background-size: 43%;
            transition: .2s all ease-in;
        }
        &:after {
            border-color: $mainColor;
            color: $mainColor;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: $mainColor;
            color:white;
            &:after {
                @include position(absolute, 3px, 4px, 0, null);
                background: url('#{$images_dir}icon_arrow_right.png') no-repeat;
                background-size: 43%;
            }
        }
    }


    img {
        &:hover {
            opacity: 1;
        }
    }
}

.btn-lg {
    padding: 20px 16px;
}

.btn-sm {

}

.btn-xs {}



.m-btn-heading {
    position: relative;
    padding: 10px 10px 10px 44px;
    border: 1px solid #d6ccba;
    background: #FFF;
    color: $textSubColor;
    border-radius: 3px;
    transition: 0.2s all ease-in;
    &:before {
        @include position(absolute, 0, null, null, 0);
        display: inline-block;
        content: '';
        width: 38px;
        height: 35px;
        background: #faf8f4;
        border-right: 1px solid #d6ccba;
    }
    &.m-btn-mail {
        &:before {
            background: #faf8f4 url('#{$images_dir}icon_mail.png') no-repeat;
            background-size: 20px 13px;
            background-position: 8px 10px;
        }
    }
    &.m-btn-line {
        &:before {
            background: #faf8f4 url('#{$images_dir}icon_line.png') no-repeat;
            background-size: 20px 20px;
            background-position: 7px 7px;
        }
    }
    &:hover,
    &:active,
    &:focus {
        color: $textSubColor;
        background: #FAF8F7;
        border: 1px solid $mainColor;
        &:before {
            transition: 0.2s all ease-in;
            border-right: 1px solid $mainColor;
        }
    }
}



.m-btn-arrow {
    text-align: left;
    padding-left: 2em;
    padding-right: 3.5em;
    &:after {
        position: absolute;
        right: .8em;
        padding-left: .6em;
        border-left: 1px solid transparent;
        content: "\f061";
        font-family: FontAwesome;
        transition: .3s;
    }
    &:hover{
        &:after{
            right: .6em;
        }
    }
}


.m-btn-white {
    border: 3px solid white;
    color: white;
    &:after {
        border-color: white;
        color: white;
    }
    &:hover {
        background-color:white;
        color:$mainColor;
        &:after {
            border-color: $mainColor;
            color: $mainColor;
        }
    }
}
