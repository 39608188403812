//header ======================================================
.header {
    //overflow: hidden;
    @extend %cf;
    @include position(fixed, 0, null, null, 0);
    width: 100%;
    height: 139px;
    padding: 0;
    background: $bg;
    z-index: 992;
    transition: all .3s ease-in;
    @include mq(tab) {
        @include position(fixed, 0, null, null, 0);
        width: 100%;
        height: 60px;
        box-shadow: 0 0 5px rgba(#000, 0.1);
    }
    .l-contents {
        position: relative;
    }
    &__left {
        float: left;
        width: 280px;
        text-align: center;
    }
    &__right {
        float: right;
    }
    &__desc {
        position: relative;
        top: 0;
        margin: 3px 0 0;
        color: #4f4f4f;
        font-size: 10px;
        letter-spacing: -0.3px;
        transition: 0.3s all;
        @include mq(tab) {
            display: none;
        }
    }
    &__logo {
        position: relative;
        display: block;
        width: 204px;
        margin: 5px auto 0;
        transition: all .3s ease-in;
        @include mq(tab) {
            width: initial;
            padding: 7px 43px 12px 10px;
            padding: 1.7vw 11vw 2.2vw 1vw;
        }
        @include mq(spsm) {
            padding: 2.6vw 24vw 2.2vw 1vw;
        }
    }
    &__btnList {
        margin: 22px 83px 0 0;
        > li {
            margin-left: 7px;
        }
        a {
            text-decoration: none;
            letter-spacing: -0.8px;
            &.m-btn-heading {
                font-size: 12px;
            }
            &.btn-reserve {
                display: block;
                margin: 1px 0 0;
                padding: 6px 10px 7px;
                color: #FFF;
                font-size: 12px;
                background: #a11047;
                border-radius: 4px;
                line-height: 1;
                border: 1px solid #a11047;
                &:before {
                    display: inline-block;
                    content: '';
                    width: 62px;
                    height: 22px;
                    margin: auto;
                    transition: .2s all ease-in;
                    background: url('#{$images_dir}icon_beauty_white.png') no-repeat;
                    background-size: 90%;
                    background-position: 1px 3px;
                    vertical-align: text-bottom;
                }
                &:hover {
                    color: #a11047;
                    background: #FFF;
                    &:before {
                        background: url('#{$images_dir}icon_beauty.png') no-repeat;
                        background-size: 90%;
                        background-position: 1px 3px;
                    }
                }
                .txt {
                    display: inline-block;
                    vertical-align: text-top;
                }
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__menu {
        display: block;
        @include position(absolute, 0, 0, null, null);
        width: 78px;
        height: 60px;
        padding: 10px 0 0;
        background: $mainColor;
        color: #FFF;
        font-size: 15px;
        text-transform: uppercase;
        text-decoration: none;
        &:after {
            position: relative;
            bottom: 0;
            display: block;
            content: '';
            width: 11px;
            height: 7px;
            margin: auto;
            background: url('#{$images_dir}icon_arrow_bottom.png') no-repeat;
            background-size: 100%;
            transition: 0.2s all ease-in;
        }
        &:hover {
            color: #FFF;
            background: darken($mainColor, 10%);
            &:after {
                position: relative;
                bottom: -3px;
            }
        }
    }
    &__info {
        opacity: 1;
        position: relative;
        bottom: 0;
        margin: 22px 0 0;
        transition: 0.3s all ease-in;
        p {
            display: inline-block;
            color: $textSubColor;
        }
        .txt {
            font-size: 12px;
            line-height: 1.5;
            text-align: left;
            letter-spacing: -0.3px;
        }
        .time {
            padding: 2px 7px;
            border: 1px solid #d6ccba;
            border-radius: 2px;
            background: #faf8f4;
            font-family: unquote($Typography-1);
            font-size: 13px;
            vertical-align: top;
            letter-spacing: -0.3px;
        }
        .phone {
            position: relative;
            margin-left: 6px;
            padding-left: 39px;
            font-family: unquote($Typography-1);
            font-size: 32px;
            line-height: 1.1;
            vertical-align: top;
            letter-spacing: -0.3px;
            &:before {
                @include position(absolute, 0, null, null, 0);
                display: inline-block;
                content: '';
                width: 34px;
                height: 34px;
                border: 1px solid #d6ccba;
                border-radius: 3px;
                background: #faf8f4 url('#{$images_dir}icon_phone.png') no-repeat;
                background-size: 16px 21px;
                background-position: 9px 5px;
            }
        }
    }
}

.is-fixed--header {
    .header {
        box-shadow: 0 0 5px rgba(#000, 0.1);
        height: 88px;
        &__logo {
            width: 160px;
            margin: 10px 0 0;
        }
        &__desc {
            position: absolute;
            top: -50px;
        }
        &__info {
            opacity: 0;
            position: relative;
            bottom: -20px;
        }
    }
    .l-main {
        padding-top: 88px;
    }
}

//nav (sp) ======================================================
.spNavFixed {
    @include position(fixed, null, 0, 0, 0);
    z-index: 995;
    ul {
        display: table;
        width: 100%;
        li {
            display: table-cell;
            width: 25%;
            border-left: 1px solid $mainColor;
            border-top: 1px solid $mainColor;
            border-bottom: 1px solid $mainColor;
            text-align: center;
            vertical-align: top;
            @include mq(spsm) {
                width: 24%;
            }
            &:first-child {
                border-left: 0;
            }
            &:last-child {
                border-left: 0;
                border-top: 1px solid #a11047;
                border-bottom: 1px solid #a11047;
                @include mq(spsm) {
                    width: 28%;
                }
            }

            a {
                display: block;
                height: 59px;
                padding: 4px 0;
                background: #FFF;
                color: $textSubColor;
                font-size: 12px;
                line-height: 1.2;
                text-align: center;
                letter-spacing: -0.3px;
                transition: .2s all ease-in;
                &:before {
                    display: block;
                    content: '';
                    width: 38px;
                    height: 35px;
                    margin: auto;
                    transition: .2s all ease-in;
                }
                &.mail {
                    &:before {
                        background: url('#{$images_dir}icon_mail.png') no-repeat;
                        background-size: 26px 18px;
                        background-position: 8px 10px;
                    }
                }
                &.line {
                    &:before {
                        background: url('#{$images_dir}icon_line.png') no-repeat;
                        background-size: 26px 27px;
                        background-position: 7px 4px;
                    }
                }
                &.phone {
                    &:before {
                        background: url('#{$images_dir}icon_phone.png') no-repeat;
                        background-size: 21px 24px;
                        background-position: 7px 4px;
                    }
                }
                &.reserve {
                    color: #FFF;
                    background: #a11047;
                    font-size: 11px;
                    letter-spacing: -0.6px;
                    &:before {
                        display: block;
                        content: '';
                        width: 78px;
                        height: 35px;
                        margin: auto;
                        background: url('#{$images_dir}icon_beauty_white.png') no-repeat;
                        background-size: 90%;
                        background-position: center;
                    }
                }
                &:hover,
                &:focus {
                    background: $textSubColor;
                    color: #FFF;
                    text-decoration: none;
                    &.mail {
                        &:before {
                            background: url('#{$images_dir}icon_mail_white.png') no-repeat;
                            background-size: 26px 18px;
                            background-position: 8px 10px;
                        }
                    }
                    &.line {
                        &:before {
                            background: url('#{$images_dir}icon_line_white.png') no-repeat;
                            background-size: 26px 27px;
                            background-position: 7px 4px;
                        }
                    }
                    &.phone {
                        &:before {
                            background: url('#{$images_dir}icon_phone_white.png') no-repeat;
                            background-size: 21px 24px;
                            background-position: 7px 4px;
                        }
                    }
                    &.reserve {
                        color: #a11047;
                        background: #FFF;
                        border-left: 1px solid #a11047;
                        &:before {
                            background: url('#{$images_dir}icon_beauty.png') no-repeat;
                            background-size: 90%;
                            background-position: center;
                        }
                    }
                }
            }
        }
    }
}

//nav ======================================================
.gnav {
    @include mq(tab) {
        @include position(absolute, 0, 0, null, null);
    }
    &__list {
        position: absolute;
        top: 40px;
        width: 100%;
        background: #072A24;
        -webkit-transition: .2s ease;
        transition: .2s ease;
        visibility: hidden;
        opacity: 0;
    }
    &__trigger {
        &:hover,
        &:focus {
            color: #FFF;
            text-decoration: none;
        }
    }
    &:hover {
        .gnav-list {
            //top: 70px;
            //right: 0;
            //visibility: visible;
            //opacity: 1;
        }
    }
}

.gnav-list {
    position: absolute;
    top: 40px;
    right: 0;
    width: 272px;
    background: #FFF;
    border: 1px solid $mainColor;
    -webkit-transition: .2s ease;
    transition: .2s ease;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    > li {
        display: block;
        border-top: 1px solid $mainColor;
        &:first-child {
            border-top: 0;
        }
        a {
            display: block;
            padding: 17px;
            color: $textColor;
            font-size: 14px;
            text-decoration: none;
            line-height: 1;
            transition: .1s all ease-in;
            text-align: left;
            @include mq(tab) {
                padding: 22px 22px 22px 12px;
            }
            @include mq(spsm) {
                padding: 16px 22px 16px 12px;
            }
            i {
                display: inline-block;
                width: 13px;
                height: 13px;
                margin-right: 8px;
            }
            &:hover {
                color: $textSubColor;
                background: #faf8f4;
            }
        }
    }
    &.is-open {
        top: 70px;
        right: 0;
        visibility: visible;
        opacity: 1;
        &:before {
            @include position(absolute, -11px, 0, 0, 0);
            display: block;
            content: '';
            width: 100%;
            height: 10px;
            background: transparent;
        }
    }
}
.gnav-list-half {
    > li {
        width: 50%;
        border-left: 1px solid $mainColor;
        &:first-child {
            border-left: 0;
        }
        a {
            padding: 17px 0;
            text-align: center;
            letter-spacing: -2.3px;
        }
    }
}


//aside ======================================================

.linkGroup {
    padding: 55px 0;
    background: $mainColor;
    @include mq(tab) {
        padding: 25px 0;
    }
    &__title {
        color: #FFF;
        font-size: 24px;
        text-align: center;
        letter-spacing: -0.3px;
        @include mq(tab) {
            font-size: 16px;
            line-height: 1.6;
        }
    }
    a {
        display: block;
        margin: 30px auto 0;
        text-align: center;
        &.phone {
            position: relative;
            color: #FFF;
            font-family: unquote($Typography-1);
            font-size: 34px;
            line-height: 1.1;
            vertical-align: top;
            letter-spacing: -0.3px;
            text-decoration: none;
            @include mq(tab) {
                margin: 20px auto 0;
                font-size: 32px;
            }
            &:before {
                @include position(relative, 4px, null, null, -7px);
                display: inline-block;
                content: '';
                width: 34px;
                height: 34px;
                border-radius: 3px;
                background: #faf8f4 url('#{$images_dir}icon_phone.png') no-repeat;
                background-size: 16px 21px;
                background-position: 10px 7px;
                @include mq(tab) {
                    @include position(relative, 2px, null, null, -7px);
                    width: 26px;
                    height: 26px;
                    border-radius: 3px;
                    background: #faf8f4 url(../images/icon_phone.png) no-repeat;
                    background-size: 48%;
                    background-position: 7px 6px;
                }
            }
        }
    }
    .time {
        display: block;
        width: 294px;
        margin: 10px auto 0;
        padding: 10px 7px;
        border-radius: 4px;
        background: #a08e6f;
        color: #FFF;
        font-family: unquote($Typography-1);
        font-size: 13px;
        text-align: center;
        letter-spacing: -0.1px;
        line-height: 1;
        @include mq(tab) {
            width: 71%;
            width: 270px;
            margin: 2px auto 10px;
        }
    }
    &__btnList {
        display: table;
        margin: auto;
        li {
            margin-left: 20px;
            &:nth-child(1),
            &:nth-child(2) {
                @include mq(tab) {
                    width: 48%;
                    margin-left: 4%;
                }
            }
            &:nth-child(3) {
                @include mq(tab) {
                    width: 100%;
                    margin-left: 0;
                }
            }

            a {
                position: relative;
                display: block;
                width: 222px;
                height: 105px;
                padding: 20px 5px;
                border-radius: 3px;
                color: #FFF;
                font-size: 14px;
                letter-spacing: -0.3px;
                text-decoration: none;
                @include mq(tab) {
                    width: 100%;
                    margin: 15px auto 0;
                    height: initial;
                    font-size: 12px;
                    padding: 10px 5px;
                }
                @include mq(spsm) {
                    font-size: 10px;
                }
            }
            .contact {
                background: #FFF;
                color: $textSubColor;
                .txt {}
                &:before {
                    display: block;
                    content: '';
                    width: 38px;
                    height: 35px;
                    margin: auto;
                    transition: .2s all ease-in;
                    @include mq(tab) {
                        height: 32px;
                    }
                }
                &.mail {
                    &:before {
                        background: url('#{$images_dir}icon_mail.png') no-repeat;
                        background-size: 26px 18px;
                        background-position: 8px 10px;
                    }
                }
                &.line {
                    &:before {
                        background: url('#{$images_dir}icon_line.png') no-repeat;
                        background-size: 26px 27px;
                        background-position: 7px 4px;
                    }
                }
                &:hover {
                    color: #FFF;
                    background: #a08e6f;
                    &.mail {
                        &:before {
                            background: url('#{$images_dir}icon_mail_white.png') no-repeat;
                            background-size: 26px 18px;
                            background-position: 8px 10px;
                        }
                    }
                    &.line {
                        &:before {
                            background: url('#{$images_dir}icon_line_white.png') no-repeat;
                            background-size: 26px 27px;
                            background-position: 7px 4px;
                        }
                    }
                }
            }
            .reserve {
                padding: 18px 5px;
                background: #a11047;
                @include mq(tab) {
                    display: table;
                    padding: 0;
                }
                &:before {
                    display: block;
                    content: '';
                    width: 85px;
                    height: 36px;
                    margin: auto;
                    transition: .2s all ease-in;
                    background: url('#{$images_dir}icon_beauty_white.png') no-repeat;
                    background-size: 90%;
                    background-position: 8px 10px;
                    @include mq(tab) {
                        display: table-cell;
                        width: 106px;
                        padding: 30px;
                        margin: auto;
                        border-radius: 3px 0 0 3px;
                        background: #FFF url('#{$images_dir}icon_beauty.png') no-repeat;
                        background-size: 90%;
                        background-position: center;
                    }
                }
                .txt {
                    @include mq(tab) {
                        display: table-cell;
                        vertical-align: middle;
                        font-size: 16px;
                        letter-spacing: -0.3px;
                    }
                    @include mq(spsm) {
                        font-size: 12px;
                    }
                }
                &:hover {
                    color: #a11047;
                    background: #FFF;
                    &:before {
                        background: url('#{$images_dir}icon_beauty.png') no-repeat;
                        background-size: 90%;
                        background-position: 8px 10px;
                    }
                }
            }

        }
    }
}






//footer ======================================================
.footer {
    position: relative;
    background: #fbfbfb;
    .l-contents {
        @include mq(tab) {
            display:-webkit-box;
            display: -webkit-flex;
            display:-ms-flexbox;
            display: flex;
            -webkit-flex-wrap:wrap;
            -ms-flex-wrap:wrap;
            flex-wrap:wrap;
            //flex-direction: column;
        }
    }
    .footerNav {
        background: #f3f2ed;
        &__list {
            display: table;
            margin: auto;
            @include mq(tab) {
                left: initial;
            }
            > li {
                display: inline-block;
                &:before {
                    display: inline-block;
                    position: relative;
                    top: 1px;
                    content: ' | ';
                    width: 10px;
                    font-size: 13px;
                }
                &:first-child {
                    &:before {
                        content: none;
                    }
                }
                > a {
                    position: relative;
                    display: inline-block;
                    padding: 15px 17px;
                    text-decoration: none;
                    color: $textColor;
                    font-size: 12px;
                    line-height: 1;
                    vertical-align: top;
                    transition: 0.3s all ease-in;
                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 33%;
                        right: 33%;
                        display: block;
                        height: 1px;
                        content: "";
                        background: #f3f2ed;
                        transition: 0.3s all ease-in;
                    }
                    &:hover {
                        color: $textSubColor;
                        &:after {
                            left: 10px;
                            right: 10px;
                            background: #b09d7c;
                        }
                    }
                }
            }
        }
    }
    &__content {
        float: left;
        width: 500px;
        padding: 30px 0 20px;
        text-align: left;
        @include mq(tab) {
            width: 100%;
            padding: 30px 0;
            float: none;
            -webkit-box-ordinal-group:1;
            -ms-flex-order:1;
            -webkit-order:1;
            order:1;
            text-align: center;
        }
    }
    &__logo {
        display: inline-block;
        width: 165px;
        line-height: 1;
        img {
            vertical-align: middle;
        }
    }
    &__address {
        display: inline-block;
        position: relative;
        top: 8px;
        margin: 0 0 0 15px;
        color: $textColor;
        font-size: 12px;
        text-align: left;
        line-height: 1.4;
        vertical-align: middle;
        @include mq(splg) {
            font-size: 11px;
        }
        @include mq(spsm) {
            font-size: 10px;
        }
    }
    &__sns {
        float: right;
        padding: 20px 0;
        @include mq(tab) {
            float: none;
            -webkit-box-ordinal-group:0;
            -ms-flex-order:0;
            -webkit-order:0;
            order:0;
            background: #f3f2ed;

            margin-right: -3.5vw;
            margin-left: -3.5vw;
            width: 110%;


            //@include position(absolute, 0, null, null, null);
        }
        ul {
            display: table;
            margin: auto;
            li {
                margin-left: 20px;
                @include mq(spsm) {
                    margin-left: 8px;
                }
            }
        }
        a {
            display: block;
            color: $textSubColor;
            font-size: 14px;
            text-decoration: none;
            line-height: 1;
            @include mq(spsm) {
                font-size: 12px;
            }
            i {
                display: inline-block;
                width: 25px;
                height: 25px;
                border: 1px solid $mainColor;
                border-radius: 50%;
                background:  $mainColor;
                &.fa {
                    border-radius: 50%;
                    color: #FFF;
                    vertical-align: top;
                    &:before {
                        position: relative;
                        top: 5px;
                        left: 1px;
                        transition: .2s all ease-in;
                    }
                }
                &.icon_add {
                    &:before {
                        display: block;
                        content: '';
                        width: 25px;
                        height: 25px;
                        margin: auto;
                        transition: .2s all ease-in;
                        background: url('#{$images_dir}icon_add_white.png') no-repeat;
                        background-size: 79%;
                        background-position: 2px 2px;
                    }
                }
                &.icon_line {
                    &:before {
                        display: block;
                        content: '';
                        width: 25px;
                        height: 25px;
                        margin: auto;
                        transition: .2s all ease-in;
                        background: url('#{$images_dir}icon_line_white.png') no-repeat;
                        background-size: 58%;
                        background-position: 4px 6px;
                    }
                }
            }
            .txt {
                vertical-align: top;
                line-height: 1.8;
                @include mq(spsm) {
                    line-height: 2.2;
                }
            }
            &:hover,
            &:focus {
                i {
                    color: $textSubColor;
                    background: #FFF;
                    border: 1px solid $mainColor;
                }
                .icon_add {
                    &:before {
                        background: url('#{$images_dir}icon_add.png') no-repeat;
                        background-size: 79%;
                        background-position: 2px 2px;
                    }

                }
                .icon_line {
                    &:before {
                        background: url('#{$images_dir}icon_line.png') no-repeat;
                        background-size: 58%;
                        background-position: 4px 6px;
                    }
                }
            }
        }
        .fb-site {
            position: relative;
            width: 380px;
            margin: 10px 0 0;
            padding: 14px 50px;
            background: #FFF;
            border-radius: 2px;
            border: 2px solid #f5f3ef;
            font-size: 14px;
            @include mq(tab) {
                width: 94%;
                margin: 15px auto 0;
                padding: 18px 20px;
                border: 2px solid #e9e4d9;
                font-size: 14px;
            }
            &:after {
                @include position(absolute, 3px, 10px, 0, null);
                display: block;
                content: '';
                width: 13px;
                height: 13px;
                margin: auto;
                background: url('#{$images_dir}icon_arrow_right_brown.png') no-repeat;
                background-size: 43%;
                transition: .2s all ease-in;
            }
            &:hover,
            &:focus {
                color: #FFF;
                background: $mainColor;
                border: 2px solid $mainColor;
                &:after {
                    @include position(absolute, 3px, 5px, 0, null);
                    background: url('#{$images_dir}icon_arrow_right.png') no-repeat;
                    background-size: 43%;
                    transition: .2s all ease-in;
                }
            }
        }
    }
    &__copyright {
        display: block;
        width: 100%;
        padding: 20px 0;
        line-height: 1;
        background: #f3f2ed;
        color: $textSubColor;
        font-size: 11px;
        text-align: center;
        text-decoration: none;
        @include mq(tab) {
            letter-spacing: 0.4px;
        }
        &:hover {
            color: #aba99d;
        }
        &--fixedMenu {
            @include mq(tab) {
                padding: 20px 0 80px;
            }
        }
    }
}