/* state
-------------------------*/

.is-inline {
    display: inline;
}

.is-inline-block {
    display: inline-block;
}

.is-block {
    display: block;
}

.is-overhidden {
    overflow: hidden;
}

.is-visible {
    visibility: visible !important;
    opacity: 1 !important;
}

.is-event--none {
    pointer-events: none !important;
}

.is-tableCell--pc {
    display: table-cell !important;
    @include mq(spsm) {
        display: none !important;
    }
}


.is-pc-hide {
    display: none !important;
    @include mq(tab) {
        display: block !important;
    }
}

.is-sp-hide {
    display: block !important;
    @include mq(tab) {
        display: none !important;
    }
}


.is-circle-img {
    border-radius: 500px;
}


.is-anchor {
    position: relative;
    top: 0;
    @include mq(tab) {
        //top: 160px;
    }
    &--searchAnc {
        top: 130px;
        @include mq(tab) {

        }
    }
    &--companyAnc {
        top: 80px;
        @include mq(tab) {

        }
    }
}


.is-fixedNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}


.header {
    &.is-fixed {
        @include mq(tab) {
            padding: 2vw 22px 0.2vw;
        }
        @include mq(splg) {
            padding: 4vw 30px 1vw;
        }
        .header__logo {
            @include mq(tab) {
                width: 25vw;
            }
            @include mq(splg) {
                width: 43vw;
            }
            &:after {
                content: none;
            }
        }
    }
}





//======================================================
// state
//======================================================//
.is-visible--sp {
    display: none !important;
    @include mq(tab) {
        display: block !important;
    }
}

.is-visible--sp5only {
    display: none !important;
    @media only screen and (max-width: 360px) {
        display: block !important;
    }
}

.is-hidden--sp {
    display: block !important;
    @include mq(tab) {
        display: none !important;
    }
}



.is-fixed-bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}

.is-not--allowed {
    &:hover {
        cursor: not-allowed !important;
        img {
            opacity: 1;
        }
    }
}

.is-colon--sp {
    // display: block !important;
    @include mq(splg) {
        display: none !important;
    }
}

.is-opacity--on {
    opacity: 1 !important;
}

