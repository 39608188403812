/* form
-------------------------*/

label {
    cursor: pointer;
}

form {
    div[class*='col-'] {
        > select {
        }
    }
    span[class*='col-'] {
    }
}

input[type="text"] {
    -webkit-appearance: none;
}

input[type="file"] {
    // line-height: 1;
}

input.btn[type="submit"] {
    // @extend %btn;
    // font-size: 16px;
    // outline: 0;
    // border: 0;
}

input[type="radio"], input[type="checkbox"] {
    // margin: 4px 3px 0;
}


.form-control {
    &--birthday {
        display: inline-block;
        width: initial;
    }
}




/*! ADD */
// form {
//     width: 100%;
//     height: 100%;
//     padding-bottom: 75px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background-image: radial-gradient(at top, #b5e6cf, #9dddad);
// }

// .select-pref {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
// }







