
/* index
-------------------------*/
.indexHero {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 536px;
    margin: 0 auto;
    border-bottom: 3px solid $textSubColor;
    @include mq(tab) {
        height: initial;
    }
    &__img {
        @include position(absolute, 0, 0, 0, 50%);
        display: block;
        max-width: initial;
        width: 1920px;
        @include mq(retina) {
            @include position(absolute, 0, 0, 0, 0!important);
            width: 100%;
        }
        @include mq(tab) {
            @include position(relative, initial, initial, initial, initial);
            width: 100%;
        }
    }
}



/* 新着ネイルデザイン */

.indexNail {
    padding: 95px 0 75px;
    @include mq(tab) {
        padding: 46px 0;
    }
    &__title {
        width: 324px;
        margin: auto;
        @include mq(tab) {
            width: 258px;
        }
        @include mq(splg) {
            width: 258px;
            width: 70vw;
        }
        .txt {
            color: $textSubColor;
            font-size: 14px;
        }
    }
    &__list {
        display: table;
        margin: 55px auto 40px;
        @include mq(tab) {
            width: 100%;
            margin: 30px auto 20px;
        }
        @include mq(splg) {
            width: initial;
        }
        li {
            margin-left: 37px;
            margin-bottom: 26px;
            @include mq(tab) {
                width: 48%;
                margin-left: 4%;
            }
            &:nth-child(4n+1) {
                margin-left: 0;
                @include mq(tab) {
                    margin-left: 4%;
                }
            }
            &:nth-child(2n+1) {
                @include mq(tab) {
                    margin-left: 0;
                }
            }
            > a {
                text-decoration: none;
                color: $textColor;
                display: block;
                &:hover {
                    img {
                        opacity: 0.5;
                        -webkit-transform: scale(1.1);
                        -moz-transform: scale(1.1);
                        -o-transform: scale(1.1);
                        -ms-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }
            }
        }
        .photo {
            @include position(relative, null, null, null, null);
            overflow: hidden;
            width: 232px;
            height: 146px;
            margin: auto;
            border: 1px dotted $mainColor;
            vertical-align: top;
            @include mq(tab) {
                width: 100%;
                height: 106px;
                height: 29.5vw;
            }
            &:before {
                @include position(absolute, 0, 0, 0, 0);
                content: "";
                background: rgba(255,255,255,0);
                border: 5px solid #FFF;
                z-index: 2;
                transition: all 0.3s ease;
                @include mq(tab) {
                    border: 3px solid #FFF;
                }
            }
            img {
                @include position(absolute, 0, 0, 0, 0);
                margin: auto;
                transition: all 0.5s ease;
            }
            &:hover {
                img {
                    opacity: 0.5;
                    -webkit-transform: scale(1.1);
                    -moz-transform: scale(1.1);
                    -o-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    transform: scale(1.1);
                }
            }
        }
        .item {
            width: 232px;
            margin: 12px auto 0;
            @include mq(tab) {
                width: 100%;
            }
            h3 {
                margin-bottom: 2px;
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: -0.4px;
                @include mq(tab) {
                    font-size: 12px;
                }
            }
            p {
                font-size: 20px;
                line-height: 1;
            }
        }
    }
}


/* サロン情報 */

.indexShop {
    position: relative;
    padding: 55px 0 70px;
    @include mq(tab) {
        padding: 37px 0;
    }
    &__right {
        float: right;
    }
    &__left {
        float: left;
    }
    &__photo {
        width: 358px;
        @include mq(tab) {
            width: 100%;
        }
        img {
            @include mq(tab) {
                margin: auto;
            }
        }
    }
    &__data {
        margin-left: 50px;
        text-align: left;
        @include mq(tab) {
            margin-left: 0;
            text-align: center;
        }
    }
    &__title {
        width: 100%;
        margin: 0 auto 10px;
        vertical-align: middle;
        .img {
            display: inline-block;
            width: 107px;
            vertical-align: text-bottom;
            @include mq(tab) {
                width: 111px;
            }
            @include mq(splg) {
                width: 111px;
                width: 29.6vw;
            }
        }
        .txt {
            display: inline-block;
            margin-left: 17px;
            color: $textSubColor;
            font-size: 14px;
            @include mq(tab) {
                display: block;
                margin-left: 0;
                margin: 10px auto 30px;
            }
        }
    }
    &__desc {
        margin: 35px 0 30px;
        font-size: 16px;
        line-height: 1.8;
        letter-spacing: -0.2px;
        @include mq(tab) {
            margin: 25px 0;
            font-size: 13px;
            text-align: left;
        }
    }
    &__more {
        display: block;
        width: 158px;
        margin: auto;
        @include mq(tab) {
            float: right;
            display: inline-block;
            width: 107px;
        }
    }
    &__list {
        display: table-cell;
        @include position(relative, -10px, null, null, null);
        width: 630px;
        vertical-align: top;
        @include mq(tab) {
            display: block;
            width: 100%;
            margin: 20px auto 0;
        }
        li {
            @include mq(tab) {
                margin: 0 0 10px;
            }
            &:last-child {
                @include mq(tab) {
                    margin: 0;
                }
            }
            a {
                color: $textColor;
                text-decoration: none;
                @include mq(tab) {
                    display: table;
                    width: 100%;
                }
                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
            time,
            p {
                display: inline-block;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                @include mq(tab) {
                    display: table-cell;
                    text-align: left;
                    vertical-align: top;
                }
            }
            time {
                @include mq(tab) {
                    width: 67px;
                    font-size: 10px;
                    line-height: 1.4;
                }
            }
            p {
                width: 528px;
                color: $linkColor;
                padding-left: 10px;
                text-align: left;
                @include mq(tab) {
                    width: initial;
                    padding-left: 6px;
                    font-size: 12px;
                    line-height: 1.4;
                }
            }
            a[class^="is"] {
                p {
                    color: $textColor;
                }
            }
        }
    }
}


/* ネイル、エステメニュー */

.indexMenu {
    position: relative;
    padding: 80px 0 60px;
    @include mq(tab) {
        padding: 37px 0;
    }
    &__right {
        float: right;
    }
    &__left {
        float: left;
        @include mq(tab) {
            float: none;
        }
    }
    &__photo {
        margin-top: -33px;
        margin-left: 50px;
        width: 529px;
        @include mq(tab) {
            width: 100%;
            margin-top: initial;
            margin-left: initial;
        }
    }
    &__data {
        text-align: left;
        @include mq(tab) {
            text-align: center;
        }

    }
    &__title {
        width: 100%;
        margin: 0 auto 10px;
        vertical-align: middle;
        @include mq(tab) {
            text-align: center;
        }
        .img {
            display: inline-block;
            width: 107px;
            vertical-align: text-bottom;
            @include mq(tab) {
                width: 111px;
            }
            @include mq(splg) {
                width: 111px;
                width: 29.6vw;
            }
        }
        .txt {
            display: inline-block;
            margin-left: 17px;
            color: $textSubColor;
            font-size: 14px;
            @include mq(tab) {
                display: block;
                margin-left: 0;
                margin: 10px auto 30px;
            }
        }
    }
    &__desc {
        width: 450px;
        margin: 35px 0 30px;
        font-size: 16px;
        line-height: 1.8;
        letter-spacing: -0.5px;
        @include mq(tab) {
            width: 100%;
            margin: 12px 0 25px;
            font-size: 13px;
            text-align: justify;
        }
    }
    &__more {
        display: block;
        width: 158px;
        margin: auto;
        @include mq(tab) {
            float: right;
            display: inline-block;
            width: 107px;
        }
    }
    &__list {
        display: table-cell;
        @include position(relative, -10px, null, null, null);
        width: 630px;
        vertical-align: top;
        @include mq(tab) {
            display: block;
            width: 100%;
            margin: 20px auto 0;
        }
        li {
            @include mq(tab) {
                margin: 0 0 10px;
            }
            &:last-child {
                @include mq(tab) {
                    margin: 0;
                }
            }
            a {
                color: $textColor;
                text-decoration: none;
                @include mq(tab) {
                    display: table;
                    width: 100%;
                }
                &:hover {
                    p {
                        text-decoration: underline;
                    }
                }
            }
            time,
            p {
                display: inline-block;
                font-family: 'Montserrat', sans-serif;
                font-size: 15px;
                @include mq(tab) {
                    display: table-cell;
                    text-align: left;
                    vertical-align: top;
                }
            }
            time {
                @include mq(tab) {
                    width: 67px;
                    font-size: 10px;
                    line-height: 1.4;
                }
            }
            p {
                width: 528px;
                color: $linkColor;
                padding-left: 10px;
                text-align: left;
                @include mq(tab) {
                    width: initial;
                    padding-left: 6px;
                    font-size: 12px;
                    line-height: 1.4;
                }
            }
            a[class^="is"] {
                p {
                    color: $textColor;
                }
            }
        }
    }
}


/* ラ・ルーナのコンセプト */

.indexConcept {
    @include position(relative, null, null, null, null);
    height: 557px;
    background: url('#{$images_dir}index_concept_bg.jpg') no-repeat;
    background-size: 100%;
    @include mq(tab) {
        width: 100%;
        height: 397px;
        //height: 133vw;
        background: url('#{$images_dir}index_concept_bg_sp.jpg') no-repeat;
        background-size: 130%;
    }
    &__boxWrap {
        @include position(absolute, null, 0, -47px, 0);
        width: 550px;
        height: 512px;
        margin: auto;
        box-shadow: 0 0 23px 12px rgba(0,0,0,.1);
        @include mq(tab) {
            bottom: -7px;
            @include position(absolute, 12.8vw, 0, initial, 0);
            width: 83%;
            height: 388px;
            height: initial;
        }
    }
    &__box {
        width: 526px;
        height: 498px;
        margin: auto;
        padding: 70px 10px 0;
        background: rgba(#FFF, 0.95);
        box-shadow: 0 0 0 12px rgba(#fff, 0.95);
        @include mq(tab) {
            width: 100%;
            height: initial;
            padding: 35px 10px;
        }
    }
    &__title {
        width: 200px;
        margin: 0 auto 30px;
        text-align: center;
        img {
            display: block;
            width: 157px;
            margin: auto;
        }
        .txt {
            display: block;
            margin-top: 15px;
            color: $textSubColor;
            font-size: 14px;
        }
    }
    &__desc {
        margin: 0 auto 50px;
        @include mq(tab) {
            margin: 0 auto 28px;
            margin: 0 auto 9vw;
            font-size: 12px;
            letter-spacing: -0.3px;
            line-height: 1.7;
        }
    }
    .m-btn--default {
        @include mq(tab) {
            padding: 19px 57px;
        }
    }
}


/* お知らせ & コラム共通 */

.indexTopics {
    padding: 130px 0 60px;
    @include mq(tab) {
        padding: 195px 0 40px;
    }
    @include mq(splg) {
        padding: 105px 0 40px;
    }

    &__heading {
        width: 100%;
        @extend %cf;
    }
    &__title {
        float: left;
        @include mq(tab) {
            display: block;
            float: none;
            margin: 0 auto;
        }
        .img {
            position: relative;
            display: inline-block;
            vertical-align: text-bottom;
        }
        .txt {
            display: inline-block;
            margin-left: 17px;
            font-size: 14px;
            color: $textSubColor;
            @include mq(tab) {
                display: block;
                margin-left: 0;
                margin: 10px auto 30px;
            }
        }
    }
    .more {
        position: relative;
        float: right;
        display: inline-block;
        margin-top: 18px;
        padding: 7px 26px;
        border-radius: 2px;
        background: $mainColor;
        color: #FFF;
        text-align: right;
        text-decoration: none;
        font-size: 11px;
        line-height: 1;
        transition: 0.3s all ease-in;
        box-sizing: border-box;
        @include mq(tab) {
            padding: 12px 26px;
        }
        &:hover {
            background: darken($mainColor, 10%);
            &:after {
                @include position(absolute, 3px, 2px, 0, null);
                display: block;
                content: '';
                width: 10px;
                height: 8px;
                margin: auto;
                background: url('#{$images_dir}icon_arrow_right.png') no-repeat;
                background-size: 43%;
                transition: .2s all ease-in;
            }
        }
    }
    &__list {
        margin: 20px auto 0;
        border-top: 1px solid #d7d2ca;
        border-right: 1px dotted #d7d2ca;
        border-bottom: 1px dotted #d7d2ca;
        border-left: 1px dotted #d7d2ca;
        background: #FFF;
        li {
            border-top: 1px dotted #d7d2ca;
            &:first-child {
                border-top: none;
            }
            a {
                position: relative;
                display: block;
                padding: 9px 18px 16px;
                color: $textSubColor;
                text-align: left;
                text-decoration: none;
                transition: 0.3s all ease-in;
                &:hover {
                    background: #fbfaf7;
                    p {
                        color: $textSubColor;
                    }
                }
                &:after {
                    @include position(absolute, 3px, 10px, 0, null);
                    display: block;
                    content: '';
                    width: 15px;
                    height: 8px;
                    margin: auto;
                    background: url('#{$images_dir}icon_arrow_right_brown_2.png') no-repeat;
                    background-size: 43%;
                    transition: .2s all ease-in;
                }
            }
            .icon-cate {
                display: inline-block;
                min-width: 100px;
                padding: 6px 10px 4px;
                border: 1px dotted #d7d2ca;
                text-align: center;
                font-size: 12px;
                line-height: 1;
                font-weight: lighter;
                background: #FFF;
            }
            time {
                padding-left: 8px;
                font-size: 12px;
                font-family: 'Avenir', 'Century Gothic', CenturyGothic;
            }
            p {
                margin-top: 5px;
                padding-right: 25px;
                color: $textColor;
                font-size: 14px;
                line-height: 1.6;
                text-align: justify;
                @include line-clamp(2);
                transition: 0.3s all ease-in;
                letter-spacing: -0.3px;
                @include mq(tab) {
                    position: relative;
                    overflow: initial;
                    text-overflow: initial;
                    -webkit-box-orient: initial;
                    -webkit-webkit-line-clamp: initial;
                    -moz-webkit-line-clamp: initial;
                    -ms-webkit-line-clamp: initial;
                    -o-webkit-line-clamp: initial;
                    -khtml-webkit-line-clamp: initial;
                    webkit-line-clamp: initial;
                }
            }
        }
    }
}

/* お知らせ */
.indexInfo {
    float: left;
    width: 520px;
    padding: 0 40px 0 60px;
    margin: auto;
    @include mq(tab) {
        float: none;
        width: 100%;
        padding: 0;
    }
    .img {
        top: 13px;
        width: 178px;
        @include mq(tab) {
            width: 212px;
        }
        @include mq(splg) {
            width: 212px;
            width: 56.7vw;
        }
    }
}

/* コラム */
.indexColumn {
    float: left;
    width: 520px;
    padding: 0 60px 0 40px;
    margin: auto;
    @include mq(tab) {
        float: none;
        width: 100%;
        padding: 58px 0;
    }
    .img {
        top: 13px;
        width: 110px;
        @include mq(tab) {
            width: 137px;
        }
        @include mq(splg) {
            width: 137px;
            width: 36.6vw;
        }
    }
    .txt {
        position: relative;
        top: 15px;
    }
}


