@charset "utf-8";

//-----------------
// 基本テキストカラー
//-----------------
$textColor: #111111; //black
$textSubColor: #b09d7c; //beige


//-----------------
// メインカラー
//-----------------
$mainColor: #b09d7c;//

//-----------------
// サブカラー
//-----------------
$subColor: #483d23;//Brown


//-----------------
// アクセントカラー
//-----------------
$accentColor: #b81c0c;//navy


//-----------------
// リンクテキストカラー
//-----------------
$linkColor: #0092ac;

//-----------------
// 基本背景カラー
//-----------------
$bg: #FFF;


//-----------------
// サブ背景カラー
//-----------------
$subBg: #f6f6f6;//gray

//-----------------
// アクセント背景カラー
//-----------------
$accentBg: #e64673;


//-----------------
// サブテキストカラー
//-----------------
//$subColor: #c30f23;


$secondaryColor: yellow;


$colorGray: #CCC;
$colorYellow: #d59921;
$colorBlue: #254899;


$colorCaution: #d73a39;



//-----------------
// その他
//-----------------
$dangerColor: #c83134;
$successColor: #1FC81F;
$btnColor: #e71c39;




$primary: #337ab7;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;