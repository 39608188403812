/* table
-------------------------*/

.m-table {
    width: 100%;
    margin-top: 1px;
    th, td {
        padding: 10px;
        text-align: center;
        @include mq(spsm) {
            padding: 7px 6px;
        }
    }
    thead {
        background: $primary;
        border-bottom: 2px solid $secondaryColor;
        th {
            color: $secondaryColor;
            font-weight: bold;
            font-size: 18px;
            @include mq(spsm) {
                font-size: 15px;
                @media only screen and (max-width: 360px) {
                    font-size: 12px;
                }
            }
        }
    }
    tbody {
        font-size: 16px;
        .detail {
            width: 110px;
            padding: 6px 5px 12px;
        }
        tr {
            &.even {
                background: #ffffea;
            }
        }
        td {
            border-bottom: 2px solid $secondaryColor;
        }
    }
}
