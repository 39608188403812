.c-line-clamp-1 {
    @include line-clamp(1);
}

.c-line-clamp-2 {
    @include line-clamp(2);
}

.c-noLink {
    color: #333;
    @include txtLine(noLine);
}

.c-hover-scale {
    img {
        &:hover {
            opacity: 0.5;
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            transition: all 0.5s ease;
        }
    }
}


.is-visibleSlide {
    display: block;
}

.bxslider {
}
/*display: none;*/


.c-bg-ptn {
    background: url('#{$images_dir}common_bg_pattern.png');
}

.c-bg-color {
    background: #f2eee7;
}

.c-doubleBorder {
    border: 1px solid #dad4c9;
    box-shadow: 0 0 0 12px rgba(#fff, 1);
}


/* xxxxxxxx */
.c-btn-submit {
    @include position(absolute, null, 10px, -50px, null);
    z-index: 991;
    display: table;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    background: $accentColor;
    vertical-align: middle;
    text-align: center;
    transition: .3s all ease-in;
    @include mq(retina) {
        @include position(absolute, null, 3vw, -55px, null);
    }
    @include mq(tab) {
        @include position(fixed, null, initial, 0, 0);
        width: 100%;
        height: initial;
        border-radius: initial;
    }
    &:hover {
        @include position(absolute, null, 3vw, -45px, null);
        background: darken($accentColor, 3%);
    }
    img {
        display: table-cell;
        width: 78px;
        margin: 0 auto;
        padding-top: 25px;
        @include mq(tab) {
            width: auto;
            padding-top: 0;
        }
    }
}

.c-btn-outline {
    display: block;
    width: 340px;
    margin: auto;
    padding: 15px 0;
    border: 1px solid #686d71;
    border-radius: 100px;
    text-align: center;
    line-height: 1;
    transition: .2s all ease-in;
    @include mq(splg) {
        width: 72vw;
    }
    &:hover,
    &:active,
    &:focus {
        background: $textColor;
    }
}


/* c-page
-------------------------*/

.c-page {
    &__heading {
        height: 326px;
        @include mq(tab) {
            height: 126px;
            margin-top: 67px;
            margin-top: 16.1vw;
        }
        @include mq(splg) {
            margin-top: 67px;
            margin-top: 17.8vw;
        }

        &--accessibility {
            background: url('#{$images_dir}accessibility_heading_bg.jpg') no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    &__title {
        text-align: center;
        margin: 55px auto 0;
        @include mq(tab) {
            margin: 13px auto 0;
        }
        img {
            display: block;
            height: 59px;
            margin: auto;
            @include mq(tab) {
                height: 29px;
            }
        }
    }
    &__bread {
        padding: 10px 0 0;
        ul {
            li {
                color: #FFF;
                font-size: 12px;
                a {
                    color: #FFF;
                    &:after {
                        display: inline-block;
                        content: '>';
                        padding: 0 10px;
                    }
                }
            }
        }

    }
}