/* base
-------------------------*/

html {
    font-size: 62.5%;
    //@include scaleFont(77.5rem, 4rem, 1.875rem, 6.250rem);
    //@include scaleFont(77.5rem, 4rem, 1.875rem, 6.250rem);
    //@include scaleFontUp(77.5rem, 0.3rem, 1rem);
}


body {
    background: $bg;
    font-family: unquote('#{$familyH}');
    //-webkit-text-size-adjust: 100%;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include font-size($baseFontSize);
    line-height: $baseLineHeight;
    @include mq(tab) {
        font-size: 13px;
    }
}


* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:before,
    &:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}


/*a*/
a {
    //color: $linkColor;
    // @include txtLine(hLine);
    color: $textColor;
    transition: .2s all ease-in;
    @include txtLine(hLine);
    &:hover {
        //color: darken($linkColor, 10%);
        color: $textSubColor;
    }
}

// Headings
// -------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
    // font-weight: normal;
}

h2 {
    //@include font-size(46);
    line-height: 1;
}




// Body text
// -------------------------
p,
li,
dt,
dd {
    @include font-size($baseFontSize);
    line-height: $baseLineHeight;
    letter-spacing: letter-spacing(50);
}




// Emphasis & misc
// -------------------------
small {
    font-size: 85%;
}

strong {
    font-weight: bold;
}

em {
    font-style: italic;
}

cite {
    font-style: normal;
}

/****/

label {
    cursor: pointer;
}

img {
    max-width: 100%;
    @include mq(spsm) {
        max-width: 100%;
    }
}

a[href*="javascript"] {
    opacity: 0.1;
    &:hover {
        cursor: default;
        opacity: 0.1 !important;
    }
}

//pagetop
.pagetop {
    display: block;
    width: 100%;
    height: 57px;
    padding: 7px 0 0;
    background: #2c2923;
    font-size: 40px;
    text-align: center;
    text-decoration: none;
    @include mq(tab) {
        height: 45px;
        padding: 0;
    }
    i {
        display: block;
        color: #FFF;
    }
}



