
/* accessibility
-------------------------*/
.accessibility {
    margin: 30px auto 80px;
    @include mq(tab) {
        margin: 20px auto 50px;
    }
    &__box {
        margin: 0 auto 40px;
    }
    &__title {
        margin-bottom: 20px;
        color: #766039;
        font-weight: bold;
        font-size: 24px;
        text-align: left;
        @include mq(tab) {
            font-size: 24px;
        }
        @include mq(tab) {
            font-size: 15px;
        }
    }
    &__content {
        padding: 25px 30px;
        border-radius: 10px;
        background: #FFF;
        @include mq(tab) {
            padding: 15px 20px;
        }
        h3 {
            text-align: left;
            font-weight: bold;
        }
        p {
            margin-bottom: 20px;
            font-size: 14px;
            text-align: justify;
            line-height: 1.6;
            &.txt-caution {
                line-height: 1.4;
                margin-bottom: 10px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
