@charset "utf-8";


@function letter-spacing ($value) {
    // If the letter-spacing $value is negative
    @if $value < 0 {
        // Convert to negative and then change unit type to em
        @return ($value * -1 / 1000) * -1em;
    }
        // If the letter-spacing $value is equal to zero
    @else if $value == 0{
        // return value unaltered
        @return $value;
    }
        // Else the $value must be positive
    @else {
        // Calculate the letter-spacing and change unit to em
        @return ($value / 1000) * 1em;
    }
}

// Example Styles
/*
h1 { letter-spacing: letter-spacing(50);}
*/


//------------------
// font-size
//------------------
//html{62.5%}つける
//@mixin font-size($size) {
//    font-size: $size + px;
//    font-size: ($size / 10) * 1rem;
//}
@mixin font-size($size: 24, $base: 16) {
    font-size: $size + px;
    font-size: ($size / $base) * 1rem;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacityIE: $opacity * 100;
    filter: alpha(opacity=$opacityIE);
}


//-----------------------------
// @extend / @mixin専用ファイル
//-----------------------------
// テキスト非表示
//-----------------------------

%off {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

/*clear fix*/
@mixin clearfix {
    &:after {
        display: block;
        visibility: hidden;
        clear: both;
        height: 0;
        content: '';
    }
}


%cf {
    &:after {
        display: block;
        visibility: hidden;
        clear: both;
        height: 0;
        content: '';
    }
}

@mixin cf {
    &:after {
        display: block;
        visibility: hidden;
        clear: both;
        height: 0;
        content: '';
    }
}



// ====================================================================
// 関数名: hide-text
// --------------------------------------------------------------------
// 概要: テキストを非表示にする
// 引数: なし
// 使用例: @include hide-text;
// 備考:
// ====================================================================
@mixin hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

// ====================================================================
// 関数名: center-block
// --------------------------------------------------------------------
// 概要: ブロック要素を左右センター寄せにする
// 引数: なし
// 使用例: @include center-block;
// 備考:
// ====================================================================
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// ====================================================================
// 関数名: linkIcon
// --------------------------------------------------------------------
// 概要: スマホサイトでよく見る、リストの矢印
// 引数:
//   $textColor:        矢印のカラー
// 使用例: @include linkIcon(#fff)
// 引用元: http://book.scss.jp/code/c5/04.html
// 備考: $setPrefixと合わせて使用
// ====================================================================
@mixin linkIcon($textColor: #333){
    $setPrefix: -webkit-, -moz-, -o-, null;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        width: 10px;
        height: 10px;
        margin-top: -7px;
        border-top: 3px solid $textColor;
        border-right: 3px solid $textColor;
        @each $prefix in $setPrefix {
            #{$prefix}transform: rotate(45deg);
        }
    }
}

@mixin a_color {
    text-decoration: none;
    color: #000;
}

// リンクカラーを一括設定
@mixin linkColor($textColor) {
    color: $textColor;
    &:hover, &:active, &:focus {
        color: lighten($textColor, 20%);
    }
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


@mixin line-clamp($maxLines:2) {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $maxLines;
    // html:not(.webkit-line-clamp) & {
    //     max-height: $maxLines * $lineHeight * 1em;
    //     &:before {
    //         top: ($maxLines - 1) * $lineHeight * 1em;
    //         @extend %line-clamp-ellipsis;
    //         color: $fgCol;
    //     }
    //     &:after {
    //         @extend %line-clamp-filler;
    //         background: $bgCol;
    //     }
    // }
}
%line-clamp-ellipsis {
    min-width: 30px;
    content: "\2026";
    position: absolute;
    right: 0;
    background: #fff;
}
%line-clamp-filler {
    $whitespace: "";
    @for $i from 1 through 1024 {
        $whitespace: $whitespace + "  ";
    }
    content: $whitespace;
    display: inline;
    letter-spacing: 1em;
    position: relative;
    z-index: 1;
    font-size: 75%;
};



// ====================================================================
// 関数名: yoko, tate
// --------------------------------------------------------------------
// 概要: デバイスの縦横判定
// 引数: なし
// 使用例: @include tate { width: 100%; },@include yoko { width: 25%; }
// 引用元: http://book.scss.jp/code/c5/04.html
// 備考:
// ====================================================================
@mixin yoko {
    @media all and (orientation:landscape) {
        @content;
    }
}
@mixin tate {
    @media all and (orientation:portrait) {
        @content;
    }
}

// ====================================================================
// 関数名: media
// --------------------------------------------------------------------
// 概要: ブレイクポイント毎のメディアクエリ
// 引数:
//   pc:       PC幅〜タブレット幅（1024px〜768px）
//   sp:        タブレット幅〜スマートフォン幅（768px〜480px）
//   iphone:    スマートフォン幅以下（480px以下）
//   [整数]+px:  任意の幅（[整数]px以下）.l;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;////
// 使用例:
//   @include media(pc)    { width: auto; margin: 0 10px; }
//   @include media(sp)     { width: 100%; margin: 0; }
//   @include media(iphone) { float: none; margin: 0 auto; }
//   @include media(800px)  { float: none; margin: 0 auto; }
// 引用元: http://book.scss.jp/code/c5/04.html
// 備考:
// ====================================================================
$bp-spSmall:    320px;
$bp-spMiddle:    375px;
$bp-spLarge:     414px;
$bp-tab:        768px;
$bp-pc:         769px;
$bp-pc15:       1440px;
$retinaRange: 1920px;

@mixin mq($media) {
    @if $media == spsm {
        @media screen and (max-width: #{$bp-spSmall}) {
            @content;
        }
    }
    @if $media == spmd {
        @media screen and (max-width: #{$bp-spMiddle}) {
            @content;
        }
    }
    @if $media == splg {
        @media screen and (max-width: #{$bp-spLarge}) {
            @content;
        }
    }
    @if $media == tab {
        @media screen and (max-width: #{$bp-tab}) {
            @content;
        }
    }
    @if $media == tab-land {
        @media only screen and (min-device-width: $bp-tab) and (max-width: 1024px) and (orientation: landscape) {
            @content;
        }
    }
    @if $media == tablg {
        @media only screen and (min-device-width: 1024px) and (max-width: 1366px) {
            @content;
        }
    }
    //@if $media == tablg-land {
    //    @media only screen and (min-device-width: 1366px) and (max-width: 1024px) and (orientation: landscape) {
    //        @content;
    //    }
    //}
    @if $media == pc {
        @media screen and (min-width: #{$bp-pc}) {
            @content;
        }
    }

    @if $media == pc-15 {
        @media screen and (max-width: #{$bp-pc15}) {
            @content;
        }
    }

    @if $media == retina {
        @media screen and (min-width: #{$retinaRange}) {
            @content;
        }
    }
}

// ====================================================================
// 関数名: parseInt
// --------------------------------------------------------------------
// 概要: 単位を外す関数
// 引数:
//   val: PC幅〜タブレット幅（1024px〜768px）
// 使用例:
//   //フォントサイズ指定 (rem)のmixin
//   @mixin font-size-rem($size) {
//     font-size: parseInt($size) + px;
//     font-size: (parseInt($size) / 10) * 1rem;
//   }
// 引用元: http://c-brains.jp/blog/wsg/13/05/27-174103.php
// 備考:
// ====================================================================

@function parseInt($val) {
    @return $val / (1 + $val - $val);
}


//------------------
// position
//------------------
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin position-center() {
    position: absolute;
    top: -100%;
    left: -100%;
    right: -100%;
    bottom: -100%;
    margin: auto;
}


//-----------------------------
// リンクテキストの下線スタイル
// a { @include txtLine(line);} //常に下線あり
// a { @include txtLine(hLine);} //hover時に下線あり
// a { @include txtLine(hNoLine);} //hover時に下線なし
// a { @include txtLine(noLine);} //常に下線無し
//-----------------------------
@mixin txtLine($style) {
    @if $style==line {
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
        }
    }
    @else if $style==hLine {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    @else if $style==hNoLine {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @else if $style==noLine {
        text-decoration: none;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}



/*
 * Mixin for placeholder
 * @include placeholderColor(#00ff00);
 */
@mixin placeholderColor($textColor) {
    &:placeholder-shown {
        color: $textColor;
    }
    &::-webkit-input-placeholder {
        color:$textColor;
    }
    &:-moz-placeholder {
        color:$textColor;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$textColor;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$textColor;
    }
}

//@include placeholderColor(#00ff00);


@mixin placeholder {
    &:placeholder-shown {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        opacity: 1;
        @content;
    }
    &::-moz-placeholder {
        opacity: 1;
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

//@include placeholder {
//    // ここに書く...
//    color: #00ff00;
//    font-size: 20px;
//    line-height: 1;
//}


@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

//.button{
//    @include linearGradient(#cccccc, #666666);
//}



@mixin gradient($from, $to) {
    background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
    background: -moz-linear-gradient(top,  $from, $to);
    filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}



@mixin box-shadow($top, $left, $blur, $textColor, $inset: false) {
    @if $inset {
        -webkit-box-shadow:inset $top $left $blur $textColor;
        -moz-box-shadow:inset $top $left $blur $textColor;
        box-shadow:inset $top $left $blur $textColor;
    } @else {
        -webkit-box-shadow: $top $left $blur $textColor;
        -moz-box-shadow: $top $left $blur $textColor;
        box-shadow: $top $left $blur $textColor;
    }
}

// -----------------------------
// エイリアス
// .dark-on-light {
//     @include font-smoothing(off);
// }
// .light-on-dark {
//     @include font-smoothing(on);
// }
// -----------------------------
@mixin font-smoothing($value: on) {
    @if $value==on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

//border-radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-radius-topleft: $radius;
    border-top-left-radius: $radius;
}
@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topright: $radius;
    border-top-right-radius: $radius;
}
@mixin border-bottom-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-radius-bottomright: $radius;
    border-bottom-right-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-bottom-left-radius: $radius;
}




// 三角(吹き出し等)
@mixin module-triangle($size: 8px, $textColor: #000, $side: top, $position: 50%, $direction: regular) {
    position: relative;
    &::before {
        content: "";
        border: solid transparent;
        display: inline-block;
        position: absolute;
        border-width: $size;
        @if $direction==reverse {
            @if $side==top {
                border-bottom-color: $textColor;
                margin-left: -$size;
                left: $position;
                bottom: -($size * 2);
            }
            @elseif $side==right {
                border-left-color: $textColor;
                margin-top: -$size;
                left: -($size * 2);
                top: $position;
            }
            @elseif $side==bottom {
                border-top-color: $textColor;
                margin-left: -$size;
                left: $position;
                top: -($size * 2);
            }
            @else {
                border-right-color: $textColor;
                margin-top: -$size;
                right: -($size * 2);
                top: $position;
            }
        }
        @else {
            @if $side==top {
                border-bottom-color: $textColor;
                margin-left: -$size;
                left: $position;
                top: -($size * 2);
            }
            @elseif $side==right {
                border-left-color: $textColor;
                margin-top: -$size;
                right: -($size * 2);
                top: $position;
            }
            @elseif $side==bottom {
                border-top-color: $textColor;
                margin-left: -$size;
                left: $position;
                bottom: -($size * 2);
            }
            @else {
                border-right-color: $textColor;
                margin-top: -$size;
                left: -($size * 2);
                top: $position;
            }
        }
    }
}

//図形テクニック1　正円
@mixin border-radius($size){
    -webkit-border-radius: $size;
    -moz-border-radius: $size;
    border-radius: $size;
}


//図形テクニック1　正円
@mixin circle($size){
    width: $size + px;
    height: $size + px;
    @include border-radius(100%);
}

@mixin radius($size) {
    @include border-radius($size + px);
}

@mixin middleline($height, $ie: 0){
    height: $height + px;
    line-height: $height + px;
    &:not(:target){
        line-height: $height + $ie + px + \9;
    }
    :root & {
        line-height: $height + $ie + px + \9;
    }
}

//------------------
// rounded 左右が丸い長方形
//------------------
@mixin rounded($height, $display: inline-block){
    $space: round($height / 2) + px;
    display: $display;
    padding: 0 $space;
    @include radius($height);
    @include middleline($height);
    text-align: center;
}

//@include rounded(25);
//@include rounded(30,block);



//------------------
// font-size
//------------------
@mixin font-size($size) {
    font-size: $size + px;
    //font-size: ($size / 10) * 1rem;
}




@mixin calcScalableFont($context-width, $base-font-size, $limit-font-size, $scale) {
    $scaleFactor: if(($scale == down), true, false); // determine direction of scale
    $scaleBoth: if(($scaleFactor == false and $scale != up), true, false); // scales "up and down"
    $limit-font-size-min: if($scaleBoth, $scale, false); // only if using "up and down" scale
    $max-width: ($limit-font-size / ($base-font-size / $context-width)); // width to calculate scaling
    $min-width: if(($limit-font-size-min), ($limit-font-size-min / ($base-font-size / $context-width)), $max-width);

    @media screen and (if($scaleFactor, min-width, max-width): $context-width) {
        @if ($scaleBoth) {
            @media screen and (max-width: $min-width)  {
                font-size: $limit-font-size-min;
            }
        } @else {
            font-size: $base-font-size;
        }
    }

    @if ($scaleBoth) {
        @media screen and (if($scaleFactor, max-width, min-width): $context-width) and (min-width: $min-width), screen and (if($scaleFactor, min-width, max-width): $context-width) and (min-width: $min-width) {
            font-size: (($base-font-size / $context-width) * 100)#{vw};
        }
    }

    @media screen and (if($scaleFactor, max-width, min-width): $context-width) {
        @if ($scaleBoth == false) {
            @media screen and (if($scaleFactor, min-width, max-width): $max-width)  {
                font-size: (($base-font-size / $context-width) * 100)#{vw};
            }
        }
        @media screen and (if($scaleFactor, max-width, min-width): $max-width) {
            font-size: $limit-font-size; // don't go below 16px!
        }
    }
}

@mixin scaleFont($context-width, $base-font-size, $limit-font-size-min, $limit-font-size-max) {
    @include calcScalableFont($context-width, $base-font-size, $limit-font-size-max, $limit-font-size-min);
}

@mixin scaleFontUp($context-width, $base-font-size, $limit-font-size) {
    @include calcScalableFont($context-width, $base-font-size, $limit-font-size, up);
}

@mixin scaleFontDown($context-width, $base-font-size, $limit-font-size) {
    @include calcScalableFont($context-width, $base-font-size, $limit-font-size, down);
}


// DEMOS:
.u-scalable-1 {
    @include scaleFont(77.5rem, 4rem, 1.875rem, 6.250rem);
}

.u-scalable-2 {
    @include scaleFontUp(77.5rem, 3rem, 3.75rem);
}

.u-scalable-3 {
    @include scaleFontDown(77.5rem, 3rem, 1.125rem);
}

.u-scalable-4 {
    @include scaleFontDown(77.5rem, 1.375rem, 1rem);
}




