@charset "utf-8";

//-----------------------------
// 変数宣言用ファイル
//-----------------------------

$images_dir: "../images/";

// Indivisual
// -------------------------
$contentWidth_pc:       1040px;
// $contentWidth_tab:      768px;
// $contentWidth_sp:       480px;
//$maincontentWidth_pc:   770px;
// $sidebarWidth_pc:       230px;

//$contentWidth: 1400px;
//$tabletWidth:640; // 全体サイズ（タブレット版）
//$spWidth:320; // 全体サイズ（SP版）


$baseFontSize: 16;//px
$baseLineHeight: 2.2;


// Typography
// -------------------------
//@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
// @import url(https://fonts.googleapis.com/css?family=Open+Sans);
// @import url(https://fonts.googleapis.com/css?family=Roboto);

// @font-face {
//     font-family: 'M+1clight';
//     src: url('mplus-1c-light.eot');
//     src: local('M+ 1c light'), local('mplus-1c-light'), url('mplus-1c-light.woff') format('woff'), url('mplus-1c-light.ttf') format('truetype'), url('mplus-1c-light.svg#mplus-1c-light') format('svg');
// }

// @font-face{
//   font-family: 'mplus-1p-regular';
//   src: url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot');
//   src: url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.eot?#iefix') format('embedded-opentype'),
//        url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.woff') format('woff'),
//        url('http://mplus-webfonts.sourceforge.jp/mplus-1p-regular.ttf') format('truetype');
// }


//$basefont: "Roboto","Noto Sans Japanese", "Noto Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", verdana, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", Sans-Serif;

@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville');

$Typography-1: "'Libre Baskerville', serif";


$familyA: "Futura, Futura-Medium, 'Futura Medium','Century Gothic', CenturyGothic, 'M+1cregular', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";
$familyB: "'ヒラギノ明朝 Pro W6', 'Hiragino Mincho Pro', 'HGS明朝E', 'ＭＳ Ｐ明朝', serif";
$familyC: "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";
$familyD: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "游明朝", "YuMincho", "ＭＳ Ｐ明朝", "MS PMincho", serif;
$familyE: "'メイリオ', Meiryo, 'YuGothic', 'Yu Gothic', '游ゴシック', '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif";
$familyF: "'Lato', 'helvetica', AppleGothic, sans-serif";
$familyG: 'Lato', 'M+1cregular', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$familyI: "Century Gothic", CenturyGothic, Futura, Futura-Medium, "Futura Medium", "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
$familyJ: Futura, "Trebuchet MS", Arial, sans-serif;
$familyH: 'Century', Century, "游明朝", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;

//------------------
// font-family
//------------------
//@mixin font_default {
//    font-family: futura, Lato, "Avenir Next", Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//}
//
//@mixin font-mplus {
//    font-family: "mplus-1c-regular", Lato, Helvetica, YuGothic, '游ゴシック', sans-serif, "メイリオ", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic";
//}
//
//@mixin font_face_mincho() {
//    font-family: "ＭＳ Ｐ明朝", "MS PMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", serif;
//}
//
//@mixin font-regular {
//    font-family: Lato, Helvetica, YuGothic, '游ゴシック', sans-serif, "メイリオ", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, Meiryo, "ＭＳ Ｐゴシック", "MS PGothic";
//}
//








