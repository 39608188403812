


body {
    &.Android {
        font-family: 'Century', Century,“Times New Roman”, "游明朝", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
        .gnav-list>li a {
            padding: 17px 22px 17px 12px;
        }
        .gnav-list {
            width: 312px;
        }
    }
}



