//======================================================
// layout
//======================================================//

.l-row-eq-height {
    display: flex;
    flex-wrap: wrap;
}


.l-wrap {
    //@include cf();
    //width: 100%;
    //height: 100%;
    //overflow: hidden;
}

.l-contents {
    @include cf();
    width: $contentWidth_pc;
    margin: 0 auto;
    text-align: center;
    @include mq(tab) {
        width: 100%;
        padding: 0 18px;
        padding: 0 3.5vw;
    }
}

.l-main {
    padding-top: 139px;
    transition: 0.3s all ease;
    @include mq(tab) {
        overflow: hidden;
        padding-top: 60px;
    }
}

.l-inner {
    width: $contentWidth_pc;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 10px;
    @include mq(tab) {
        width: 100%;
        padding: 6%;
    }
    @include mq(spsm) {
        padding: 3%;
    }
}

.l-btn-wrap {
    padding: 4%;
    &--cta {
        padding: 2% 4%;
    }
}

.l-grid {
    @extend %cf;
    > li {
        float: left;
        &:first-child {
            margin-left: 0 !important;
        }
    }
}

.l-right {
    float: right;
    &--pc {
        float: right;
        @include mq(spsm) {
            float: none;
        }
    }
    &--sp {
        float: none;
        @include mq(spsm) {
            float: right;
        }
    }
}

.l-left {
    float: left;
    &--pc {
        float: left;
        @include mq(spsm) {
            float: none;
        }
    }
    &--sp {
        float: none;
        @include mq(spsm) {
            float: left;
        }
    }
}

