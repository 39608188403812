/* utility
-------------------------*/
.u-color {
    color: $textColor !important;
}

.u-subColor {
    color: $subColor !important;
}

.u-accentColor {
    color: $accentColor !important;
}

.u-linkColor {
    color: $linkColor !important;
}

.u-bg {
    background-color: $bg !important;
}

.u-subBg {
    background-color: $subBg !important;
}

.u-accentBg {
    background-color: $accentBg !important;
}

.u-dangerColor {
    color: $dangerColor !important;
}

.u-whiteColor {
    color: white;
}

.u-blackColor {
    color: #333;
}

.u-accentColor {
    color: $accentColor;
}

.u-text--caution {
    text-indent: -1em;
    padding-left: 1em;
    color: $colorCaution;
}

.u-text--bold {
    font-weight: bold;
}

.u-text-red {
    color: #D50000;
}

.u-text-green {
    color: #23941e;
}
.u-text-greenLight {
    color: #21b7b6;
}
.u-text-blue {
    color: #4081B7;
}
.u-text-brown {
    color: #B78940;
}
.u-text-lime {
    color: #B8BD2B;
}
.u-text-orange {
    color: #f3813b;
}


.text-blue {
    color: $accentColor;
}


/* ==========================
u-text
========================== */
.u-marker--red {
    background: linear-gradient(transparent 50%, $accentColor 50%);
}

.u-marker--yellow {
    background: linear-gradient(transparent 50%, $accentColor 50%);
}

.u-marker {
    position: relative;
    &:after {
        @include position(absolute, null, 0, -7px, 0);
        content: '';
        width: 100%;
        height: 4px;
    }
    &--red-line {
        &:after {
            background: $accentColor;
        }
    }
    &--yellow-line {
        &:after {
            background: $colorYellow;
        }
    }
}


/* ==========================
u-fs // フォントサイズ
========================== */
@for $i from 10 through 40 {
    .u-fs#{$i} {
        font-size:#{$i}px;
    }
}


/* ==========================
u-m
========================== */
@for $i from 10 through 40 {
    .u-m#{$i} {
        margin:#{$i}px;
    }
}

@for $i from 10 through 40 {
    .u-mt#{$i} {
        margin-top:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-mr#{$i} {
        margin-right:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-mb#{$i} {
        margin-bottom:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-ml#{$i} {
        margin-left:#{$i}px;
    }
}

/* ==========================
u-p
========================== */
@for $i from 10 through 40 {
    .u-p#{$i} {
        padding:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-pt#{$i} {
        padding-top:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-pr#{$i} {
        padding-right:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-pb#{$i} {
        padding-bottom:#{$i}px;
    }
}
@for $i from 10 through 40 {
    .u-pl#{$i} {
        padding-left:#{$i}px;
    }
}


/* ==========================
u-w
========================== */
//@for $i from 1 through 40 {
//    .u-w#{$i}p {
//        width:#{$i}%;
//    }
//}

/* ============
* u-w*p
============== */

.u-w10p {
    width: 10% !important;
}

.u-w20p {
    width: 20% !important;
}

.u-w30p {
    width: 30% !important;
}

.u-w40p {
    width: 40% !important;
}

.u-w50p {
    width: 50% !important;
}

.u-w60p {
    width: 60% !important;
}

.u-w70p {
    width: 70% !important;
}

.u-w80p {
    width: 80% !important;
}

.u-w90p {
    width: 90% !important;
}

.u-w100p {
    width: 100% !important;
}
